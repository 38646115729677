window.onload = ()=>{
    const youtubeFrame = '<iframe width="70%" height="70%" src="%link%" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>\n'
    let youtubeEl = document.getElementsByClassName("youtube-video")[0];
    let playButtons = document.getElementsByClassName("play-wrapper");

    let isMatchMedia = false;

    youtubeEl.onclick = () => {
        youtubeEl.innerHTML = '';
        youtubeEl.style.display = 'none';
    }


    for (let playButton of playButtons) {

        let playIcon = playButton.getElementsByClassName("play");
        if(playIcon.length > 0) {
            playButton.style.cursor = "pointer";
            playButton.onclick = ()=>clickPlay(playButton);
        } else {
            let bannerImg = playButton.getElementsByClassName("banner-image")[0];
            bannerImg.classList.remove("play-wrapper-anim-image")
        }
    }

    function clickPlay(e) {
        if(isMatchMedia) {
            let ytLink = e.attributes.getNamedItem("yt-open").value;
            window.open(ytLink,"_blank")
        } else {
            let ytLink = e.attributes.getNamedItem("yt").value;

            if (ytLink !== "") {
                youtubeEl.style.display = 'flex';

                youtubeEl.innerHTML = youtubeFrame.replace("%link%", ytLink);
            }
        }
    }



    const mediaQuery = window.matchMedia('(max-width: 1200px)')

    function handleTabletChange(e) {
        isMatchMedia = e.matches;
    }

    mediaQuery.addListener(handleTabletChange)

    handleTabletChange(mediaQuery)



    let body = document.body;

    let maxScroll = body.scrollHeight - window.innerHeight;
    let starEl = document.getElementById("star");

    let startPosStar = starEl.offsetTop;

    let ticking = false;
    document.addEventListener("scroll", (event) => {
        let scrollY = window.scrollY;

        let scrollFromTop = maxScroll * 0.2;

        let deltaPosStar = scrollY / scrollFromTop;





        if (!ticking) {
            window.requestAnimationFrame(() => {
                starEl.style.top = startPosStar - 600 * deltaPosStar + "px";
                ticking = false;
            });

            ticking = true;
        }
    });


    let bottomIcon = document.getElementById("bottom-icon");
    bottomIcon.onclick = () =>{
        function moveScrollFrame() {
            document.documentElement.scrollTop-=200;
            console.log(document.documentElement.scrollTop)
            if(document.documentElement.scrollTop > 0)
                window.requestAnimationFrame(moveScrollFrame)
        }

        window.requestAnimationFrame(moveScrollFrame);

    };


}

